
.popup-container {
  display: inline-block;
  position: relative;
}

.popup-content {
  margin-top: 7px;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  /* padding: 10px; */
  border: 1px solid F3F3F3;
  border-radius: 6px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.poly_icon {
  position: absolute;
  top: -8px;
  right: 43px;
}

.popup_body {
  width: 20rem;
  padding-bottom: 30px;
}

.white_polygone_position {
  position: absolute;
  top: -20px;
  right: 6px;
}

.popup-trigger {
  padding: 5px;

}

.custom-input {
  border: none;
  padding: 10px;
  font-size: 16px;
  padding-left: 25px;
  outline: none;
  flex: 6;
  width: 100%;
}

.custom-input::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: #A3AED0;

}

.custom-button {
  background-color: #5367FC;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100px;
  width: 100%;
  flex: 1;
}

.custom-button:hover {
  background-color: #0056b3;
}

.share_wr {
  align-items: center;
  display: flex;
}

.share_wr img {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}

.share_wr span {
  font-size: 16px;
  font-weight: 500;
}

.filter_wr {
  border-radius: 5px;
  background: #E5EDFF;
}

.filter_by_text {
  color: #231F20;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.dropDown_wr {
  background-color: #F8F8FF;
  margin-top: 5px
}

.filter_by_text_wr {
  padding: 20px 20px 0px;
}


.filter_by_body,
.sort_body {
  padding: 20px;
}