.open-account-popup {
    width: 440px;
    height: auto;
    position: relative;
    position: fixed;
    right: 0;
    bottom:0;
}

.open-account-popup .item {
    width: 100%;
    height: 160px;
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    display: flex;
    background: #fff;
}

.open-account-popup .item.blue-back {
    background: #7787FD;
}

.open-account-popup .item.yellow-back {
    background: #FFDE2D;
}

.open-account-popup .close-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 9;
}

.open-account-popup .item .back {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
}

.open-account-popup .item .text {
    width: 55%;
    height: auto;
    flex: 0 0 55%;
    padding: 20px 0 20px 30px;
    position: relative;
    z-index: 9;
}

.open-account-popup .item .text h4 {
    font-size: 16px;
    color: #5367FC;
    margin-bottom: 30px;
    line-height: 25px;
}

.open-account-popup .item.blue-back .text h4 {
    color: #fff;
}

.open-account-popup .item.yellow-back .text h4 {
    color: #263238;
}

.open-account-popup .item .text .btun {
    background: #5367FC;
    color: #fff;
    padding: 10px 35px;
    border-radius: 30px;
    font-size: 12px;
    cursor: pointer;
    text-decoration: none;
}

.open-account-popup .item.blue-back .text .btun {
    background: #fff;
    color: #5367FC;
}

.open-account-popup .item.yellow-back .text .btun {
    background: #263238;
    color: #fff;
}

.open-account-popup .item.yellow-back .image {
    width: 40%;
    flex: 0 0 40%;
}

.open-account-popup .item .image {
    width: 45%;
    height: auto;
    flex: 0 0 45%;
    padding-right: 15px;
    display: flex;
    align-items: flex-end;
}

.open-account-popup .item .image img {
    width: 100%;
    overflow: hidden;
}

.open-account-popup .owl-dots {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
    margin-top: 0;
}

.open-account-popup .owl-theme .owl-dots .owl-dot span {
    background: #263238;
}

.open-account-popup .owl-theme .owl-dots .owl-dot.active span,
.open-account-popup .owl-theme .owl-dots .owl-dot:hover span {
    background: #5367FC;
}
@media screen and (max-width: 768px) {
    .open-account-popup{
        width:300px;
        right: 15px;
    }
    .open-account-popup .item{
        height: 120px;
    }
    .open-account-popup .item .text h4 {
        font-size: 12px;
        color: #5367FC;
        margin-bottom: 25px;
        line-height: 18px;
    }
    .open-account-popup .item .text .btun {
        background: #5367FC;
        color: #fff;
        padding: 6px 23px;
        border-radius: 30px;
        font-size: 11px;
        cursor: pointer;
    }
    .open-account-popup .item .text{
        width: 55%;
        flex: 0 0 55%;
        padding: 15px 0 15px 20px;
    }
    .open-account-popup .item .image{
        width: 45%;
        flex: 0 0 45%;
    }
    .owl-theme .owl-nav.disabled + .owl-dots {
        margin-top: 10px;
        width: 55px;
    }
}