.table-pagination-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.table-pagination{
    width: auto;
}
.table-pagination .pagination-buttons button {
    background: #fff;
    border: none;
    border-radius: 5px;
    padding: 0px 0px;
    margin-left: 10px;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    box-shadow: none;
}

.table-pagination .pagination-buttons button.inactive-page {
    padding: 0;
}

.table-pagination .pagination-buttons button.active-page {
    padding: 0;
    background: #5367fc;
    color: #fff;
}

.table-pagination .pagination-buttons span {
    margin-right: 5px;
    border: none;
}

.pagination-info {
    margin-left: 10px;
    color: #808080;
    /* Adjust margin as needed */
}

.pagination-buttons button:hover {
    background-color: #f0f0f0;
}
.table-pagination button.inactive{
    background: rgba(186, 186, 186, 0.65);
    border:1px solid rgba(186, 186, 186, 0.65);
    color: #fff;
    font-size: 22px;
    font-weight: bold;
    line-height: 1;
    padding-bottom: 5px;
}
.table-pagination button.active{
    background: #fff;
    border:1px solid #5367fc;
    color: #5367fc;
    font-size: 22px;
    font-weight: bold;
    line-height: 1;
    padding-bottom: 5px;
}
.table-pagination .pagination-buttons span {
    font-size: 26px;
    padding: 0;
}
@media screen and (max-width: 992px) {
    .table-pagination-row{
        display: block;
    }
    .pagination-info{
        text-align: center;
        font-size: 14px;
    }
    .table-pagination .pagination-buttons button{
        width: 30px;
        height: 30px;
        margin: 5px;
    }
    .table-pagination button.inactive{
        font-size: 23px;
    }
    .table-pagination button.active{
        font-size: 23px;
    }
    .pagination-buttons{
        margin: auto;
    }
}