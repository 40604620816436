body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins', sans-serif;
  color: #545A6D;
  background-color: #f9f9ff !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.blue_btn {
  border-radius: 6px;
  background: #5367FC;
  color: #fff;
  display: inline-block;
  padding: 5px 10px;
  text-decoration: none;
}

.blue_btn.light {
  background: rgba(83, 103, 252, 0.49);
}

.referral_text_wr .form-check-label {
  color: #fff !important;
}

body {
  overflow-x: hidden;
}

body:before {
  background-image: url("http://bigul.co/en/wp-content/uploads/2023/11/body-bg-1.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: left;
  background-position-y: top;
  width: 100px;
  height: 100%;
  content: "";
  position: absolute;
  left: -35px;
  top: 125px;
  opacity: 0.3;
}

body:after {
  background-image: url("http://bigul.co/en/wp-content/uploads/2023/11/body-bg-2.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: right;
  background-position-y: top;
  width: 100px;
  height: 100%;
  content: "";
  position: absolute;
  top: 125px;
  opacity: 0.3;
  right: -35px;
}

.container.main-dive.m-px-30 {
  padding-left: 28px;
  padding-right: 28px;
}

@media (max-width: 1200px) {

  body:before,
  body:after {
    display: none;
  }
}

@media (max-width: 767px) {
  .container.main-dive {
    padding-left: 15px;
    padding-right: 15px;
  }

  .container.main-dive.m-px-30 {
    padding-left: 28px;
    padding-right: 28px;
  }

}