.Left-container {
	width: 30% !important;
	align-items: center;
	justify-content: center;
}

.otp-container {
	display: flex;
	justify-content: space-between;
}

.otp-input {
	width: 40px;
	/* Adjust the width of each input field */
	height: 40px;
	/* Set the height of each input field */
	border: 1px solid #ccc;
	/* Set border */
	border-radius: 8px;
	/* Set border radius */
	font-size: 16px;
	/* Adjust font size */
	text-align: center;
	/* Center align text */
	margin-right: 10px;
	/* Adjust margin between input fields */
}

.Right-side {
	padding-top: 30px;
}

.mide {
	justify-content: center;
	align-items: center;
}

.Icon-container {
	/* margin-top: 30px; */
	/* margin-left: 100px; */

}

.Login-text-1 {
	font-size: 20px;
	font-weight: 500;
	color: #545A6D;
	font-family: Poppins;
	line-height: 30px;
}

.text-container {
	/* justify-content: center; */
	align-items: center;

}

.align-self-center {
	display: flex;
	justify-content: right;
}

.mide-section {
	align-self: start;
}

.Card-view {
	display: flex;
	height: 50%;
	margin-top: 10%;
}

.mobile-icon {
	width: 24px;
	height: 24px;
}

.Icon-container {
	display: flex;
	flex-direction: row;
}

.email_wr {
	margin-bottom: 15px;
}

.email_wr img {
	margin-right: 10px;
}

.email_wr>span {
	color: #050505;
	font-size: 16px;
	font-weight: 600;
	font-family: Poppins;
}

.Ipute-user {
	border-width: 0px;
	color: #989898;
	font-size: 16px;
	font-weight: 400;
	width: 100%;
	font-family: Poppins;
	padding: 0 15px;
	height: 56px;
}

.passwrod_input_wr {
	padding: 0px;
	height: 56px;
	width: 100%;
	border-width: 1px;
	border-radius: 10px;
	border-color: #DFDFDF;
	border-style: solid;
	justify-content: space-between;
	display: flex;
	overflow: hidden;
	align-items: center;
}

.passwrod_input_wr img {
	cursor: pointer;
	height: auto;
	margin-left: 5px;
	margin-right: 5px;
	width: 20px;
}

.form-check-label {
	font-family: Poppins;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	text-align: left;
	color: #666666 !important;
}

.btn-primary {
	border-radius: 10px;
	margin-bottom: 30px;
	padding: 12px 10px !important;
	background: #5367FC !important;
}

.remember_wr {
	margin-top: 30px;
	display: flex;
	flex-direction: row;
	width: 100%;
	max-width: 634px;
	justify-content: space-between;
	align-items: center;
}

.remember_wr>span {
	font-family: Poppins;
	font-size: 16px;
	font-weight: 400;
	color: #666666;
}

.mobile-number-container {
	display: flex;
	justify-content: flex-start;
	margin-top: 20px;
	flex-direction: column
}

.edit-number {
	color: #5367FC;
	text-decoration: underline;
	margin-left: 10px;
}

.Mobile-numebr-text {
	color: #545A6D;
	font-weight: 500;
	margin-left: 10px;
}

.Inpute-bottom-text {
	margin-top: 10px;
	margin-left: 25px;
	color: #797979;
	font-size: 14px;
}

.otp-text-container {
	margin-top: 20px;
}

.botton-style {
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
	display: flex;
	justify-items: center;

}

.botton-conatiner {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

.otp-text {
	/* margin-left: 100px; */
	margin-bottom: 10px;
	display: flex;
	flex-direction: row;
}

body:before,
body:after {
	display: none;
}


.login-wraper {
	width: 100%;
	height: auto;
	display: flex;
	background: #fff;
}

.banner-part {
	width: 50%;
	flex: 0 0 50%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.banner-part img {
	height: 100%;
	width: 100%;
}

.text-part {
	width: 50%;
	flex: 0 0 50%;
	height: 100vh;
	padding: 20px 80px;
	box-sizing: border-box;
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	flex-direction: column;
}

.text-part .logo-header {
	width: 100%;
	height: auto;
}

.text-part .login-box {
	width: 100%;
	height: auto;
	position: relative;
}

.text-part .login-box .title-1 {
	text-align: center;
	color: #545A6D;
	font-size: 20px;
	font-weight: 500;
	width: 100%;
	position: relative;
	line-height: 30px;
	padding: 8px;
	margin-bottom: 50px;
}

.text-part .login-box .title-1:before {
	content: '';
	width: 100px;
	height: 1px;
	background: #AFAFAF;
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
	bottom: 0;
	box-sizing: border-box;
}

.text-part .login-box .form-box {
	width: 100%;
	height: auto;
	position: relative;
}

.text-part .login-box .form-box .form-group {
	width: 100%;
	height: auto;
	position: relative;
}

.text-part .login-box .form-box .form-group label {
	display: flex;
	align-items: center;
	font-size: 16px;
	color: #545A6D;
	font-weight: 500;
	line-height: 24px;
	margin-bottom: 20px;
}


.text-part .login-box .form-box .form-group label img {
	margin-right: 10px;
}

.text-part .login-box .form-box .form-group .form-control {
	width: 100%;
	height: 48px;
	border-radius: 30px;
	border: 1px solid #F0F0F0;
	padding: 12px 18px;
	box-sizing: border-box;
	font-size: 16px;
	-webkit-box-shadow: 0px -3px 10px 0px rgba(199, 199, 199, .25);
	-moz-box-shadow: 0px -3px 10px 0px rgba(199, 199, 199, .25);
	box-shadow: 0px -3px 10px 0px rgba(199, 199, 199, .25);
	margin-bottom: 10px;
}

.text-part .login-box .form-box .form-group .form-control::placeholder {
	color: #A3AED0;
}

.text-part .login-box .form-box .para {
	font-size: 14px;
	color: #797979;
	line-height: 21px;
	margin-left: 20px;
	margin-bottom: 30px;
}

.text-part .login-box .form-box .resend {
	color: #5367FC;
	text-decoration: none;
	margin-bottom: 40px;
	display: table;
}

.text-part .login-box .form-box .send-btn {
	display: table;
	margin: auto;
	background: #5367FC;
	font-size: 16px;
	line-height: 24px;
	color: #fff;
	font-weight: 500;
	border: none;
	border-radius: 30px;
	padding: 9px 25px;
	box-sizing: border-box;
	width: 130px;
	cursor: pointer;
}

.text-part .login-box .form-box .back-btn {
	font-size: 16px;
	line-height: 24px;
	color: #797979;
	display: table;
	margin: auto;
	background: transparent;
	border: none;
	font-weight: 500;
	margin-top: 15px;
	cursor: pointer;
	box-shadow: none;
}



.text-part .status-bar {
	height: auto;
	width: 100%;
	display: flex;
	justify-content: center;
	margin-bottom: 20px;
}

.text-part .status-bar .bar {
	width: 52px;
	height: 8px;
	border-radius: 5px;
	background: #fff;
	border: 1px solid #797979;
	margin: 0 4px;

}

.text-part .status-bar .bar.active {
	background: #5367FC;
	border-color: #5367FC;
}

.otp-field input[type=number] {
	margin-bottom: 10px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.otp-field div {
	justify-content: space-between;
}

.otp-field input[type="number"]::-webkit-inner-spin-button,
.otp-field input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	appearance: none;
	margin: 0;
}

.otp-field input[type=number] {
	width: 85px !important;
	height: 48px;
	border-radius: 30px;
	border: 1px solid #F0F0F0;
	padding: 12px 18px;
	box-sizing: border-box;
	font-size: 16px;
	-webkit-box-shadow: 0px -3px 10px 0px rgba(199, 199, 199, .25);
	-moz-box-shadow: 0px -3px 10px 0px rgba(199, 199, 199, .25);
	box-shadow: 0px -3px 10px 0px rgba(199, 199, 199, .25);
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.update_mobile {
	text-align: center;
	text-decoration: none;
	color: #5367FC;
	display: table;
	margin: auto;
	margin-top: 28px;
}

@media screen and (max-width: 992px) {
	.login-wraper {
		display: block;
	}

	.banner-part {
		display: none;
	}

	.text-part {
		width: 100%;
		padding: 20px 20px;
	}

	.text-part .login-box .title-1 {
		font-size: 18px;
	}

	.text-part .login-box .form-box .para {
		font-size: 12px;
	}

	.otp-field input {
		width: 100% !important;
		padding: 5px;
		margin: 0 3px;
		border-radius: 15px;
	}

	.otp-field input[type=number] {
		width: 42px !important;
		height: 48px;
		border-radius: 15px;
		border: 1px solid #F0F0F0;
		padding: 5px 4px;
		box-sizing: border-box;
		font-size: 16px;
		box-shadow: 0px -3px 10px 0px rgba(199, 199, 199, .25);
		appearance: none;
	}
}