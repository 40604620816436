.logo {
    width: 12rem;
}

.search_bar {
    position: relative;
}

.search_bar input {
    padding-left: 35px;
    height: 47px;
    background-color: #f6f6fc;
    color: #A3AED0;
    font-weight: 400;
    border: none;
    font-family: 'Poppins', sans-serif;
    border-radius: 4px;
    max-width: 280px;
    width: 100%;
}

.custom-calendar-day {
    background-color: #FFFFFF;
}

input:focus {
    outline: none;
}

.main_nav_wr .sidebar-close {
    display: none;
    position: relative;
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: 0;
    margin-top: -10px;
    margin-bottom: -15px;
}

.main_nav_wr .sidebar-close::before {
    content: "";
    width: 20px;
    height: 1px;
    background-color: #333;
    border-radius: 2px;
    top: 20px;
    left: 5px;
    position: absolute;
    transform: rotate(45deg);
}

.main_nav_wr .sidebar-close::after {
    content: "";
    width: 20px;
    height: 1px;
    background-color: #333;
    border-radius: 2px;
    top: 20px;
    left: 5px;
    position: absolute;
    transform: rotate(-45deg);
}

.mobile-toggle-icons {
    display: none;
    /* margin-left: 0;
    margin-right: auto; */
    width: 28px;
    height: 32px;
    cursor: pointer;
    padding: 0px;
    margin-left: 10px;
    position: relative;
}

.mobile-toggle-icons>span {
    height: 2px;
    background-color: #333;
    border-radius: 2px;
    display: block;
    width: 24px;
    cursor: pointer;
    position: absolute;
    left: 0;
}

.mobile-toggle-icons>span:first-child {
    top: 10px;
}

.mobile-toggle-icons>span:nth-child(2) {
    top: 17px;
}

.mobile-toggle-icons>span:last-child {
    top: 25px;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 12px 12px !important;
}

.date_picker .react-datepicker__header {
    background-color: #5367FC;
    color: #fff;
}

.total_ac .card_wr {
    padding-top: 15px;
    padding-bottom: 15px;
    height: 100%;
}

.date_picker .react-datepicker__current-month,
.date_picker .react-datepicker__current-year {
    font-size: 12px;
    color: #fff;
    /* Set your desired text color here */
}

.date_text_field {
    height: 47px;
    border: none;
    width: 100%;
    color: #A3AED0;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    margin-right: 60px;
}

.date_picker_wr {
    display: flex;
    justify-content: space-between;
}

.date_picker {
    width: 48%;
    display: inline-block;
    /* display: grid; */
    /* justify-content: space-between;
    display: flex; */
    /* padding-left: 20px;
    padding-right: 20px; */
    border-radius: 6px;
    /* width: 100%; */
    margin-top: 20px;
    box-shadow: rgb(100 100 111 / 22%) 2px 3px 10px 0px;
}


.search_bar img {
    position: absolute;
    top: 13px;
    left: 10px;
}

.search_bar input:focus {
    border: none;
    box-shadow: none;
    background-color: #F7F7FF;
}

.bell_icon {
    width: 44px;
    height: 43px;
    background: #EFEFEF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-right: 30px;
}

.bell_icon img {
    width: 24px;
    height: 24px;
}

.not_count {
    width: 20px;
    height: 20px;
    background-color: #FF0707;
    border-radius: 50%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -2px;
    font-size: 12px;
    right: -1px;
}

.profile_wr {
    display: flex;
    align-items: center;
    background-color: #F5F8FF;
    padding: 10px;
    width: 18rem;
}

.profile_wr img {
    width: 50px;
    height: auto;
}

.profile_wr>div {
    flex-direction: column;
}

.profile_wr>div span:first-child {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    margin-bottom: 0;
}

.profile_wr>div span:last-child {
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
}

.top_sub_menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
}

.top_sub_menu .right-side,
.top_sub_menu .left-side {
    align-items: center;
}

.top_bar {
    box-shadow: 0px 5px 20px 0px #C9C9C940;
    background-color: #fff;
}

.top_sub_menu_wr {
    border-bottom: 1px solid rgba(237, 237, 237, 1);
}

.main_nav_wr {
    padding-top: 0px;
    padding-bottom: 0px;
    display: flex;
    justify-content: space-between;
}

.main_nav_wr ul a {
    padding-top: 15px;
    padding-bottom: 15px;
}

.main_tabs>div>img {
    width: 24px;
    height: auto;
}

.main_tabs>div>span {
    font-size: 15px;
    font-weight: 400;
}

.main_tabs li.hovered a {
    color: #00a0d2;

}

.menu_ancher {
    text-decoration: none;
}


.uncoveredLead_filter {
    padding-bottom: 10px;
}

.refferal_link_section {
    /* padding-left: 18px !important; */
}

.form-select {
    border: none;
    padding: 9px 60px 9px 30px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 29px 0px;
}

.stagewise_section h3 {
    margin-left: 5px;
}

.greetings h3 {
    color: #545A6D;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: initial;
}

.greetings p {
    color: #8089A4;
    font-size: 14px;
    font-weight: 400;
}

.total_ac_card {
    display: flex;
    align-items: center;

}

.card_text_wr {
    width: 78%;
}

.card_wr {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 29px 0px;
    border-radius: 10px;
    padding: 20px;
}

.main_body_content>div {
    /* margin-left: 0px; */
}

.view_more_text {
    color: #5367FC;
    font-weight: 500;
    font-size: 14px;
}

.view_more_wr {
    display: flex;
    align-items: end;
    justify-content: space-between;
}

.total_ac_card img {
    width: 48px;
    height: auto;
}

.total_ac_card>div {
    flex-direction: column;
    color: #545A6D;
}

/* .total_ac_card .noOf_count{
   font-size: 18px;
   font-weight: 500;
} */
.noOf_count {
    color: #231F20 !important;
    font-size: 24px !important;
    font-weight: 500;

}

/* .view_more_text:hover{
    color: red !important ;
} */

.clients_value_text {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
}

.clients_value {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    color: #000
}

.total_ac_card span:first-child {
    font-size: 14px;
    font-weight: 500;
    white-space: initial;
}

.reqPayout_num {
    font-size: 24px !important;
    font-weight: 500 !important;
    color: #231F20;
}

.req_payout_wr {
    justify-content: center;
    border-color: rgba(83, 103, 252, 0.4);
    border-width: 1px;
    border-style: solid;
    border-radius: 15px;
    background-color: #fff;
    padding: 10px;
}

.clien_wise_text {
    margin-bottom: 20px;
    background-color: #fff;
}

.withdraw_text {
    font-weight: 500;
    font-size: 16px;
    columns: #231F20;
}

.card_lines {
    justify-content: center;
    align-items: center;
}

.amout_search_wr {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 29px 0px;
}

.card_text {
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
}

.main-dive {
    margin-top: 20px;
}

.total_ac_card_image {
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
}

.total_ac_image {

    background-color: #F15B4D;
}

.total_trade_ac {
    background-color: #5367FC;
}

.project_payout {
    background-color: #05CD99;
}

.total_earning {
    background-color: #9500AE;
}

.total_ac_card_image img {
    align-self: center;
    width: 32px;
    height: auto;
}

.lead_summry_container {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 29px 0px;
    /* display: flex; */
    align-items: center;
    border-radius: 10px;
    padding: 20px;
    background-color: #fff;
    height: 100%;
}

.referral_wr {
    background-color: #00507D;
}

.line {
    border-bottom: 1px #EFEFEF solid;
    margin: 18px;
}

.stage_wise_wr {
    margin-top: 10px;
}

.referralz_wr {
    background-color: #00507D
}

.referral_text_wr {
    padding: 15px 0 15px 15px;

}

.form-check {
    padding-right: 25px;
}

.form-check-label {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
}


.referral_text {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    /* margin-bottom: 10px; */
}

.refrel_links_wr {
    display: flex;
    border-radius: 20px;
    overflow: hidden;
}

.amout_inpute_box {
    border-radius: 10px;
    overflow: hidden;
}


.custom-input {
    border: none;
    padding: 10px;
    font-size: 16px;
    padding-left: 25px;
    outline: none;
    flex: 6;
    width: 100%;
}

.custom-input::placeholder {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: #A3AED0;

}

.custom-button {
    background-color: #5367FC;
    color: white;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100px;
    width: 100%;
    flex: 1;
}

.custom-button:hover {
    background-color: #0056b3;
}

.share_wr {
    align-items: center;
    display: flex;
}

.share_wr img {
    width: 14px;
    height: 14px;
    margin-right: 5px;
}

.share_wr span {
    font-size: 16px;
    font-weight: 500;
}

.share_image_wr {
    text-align: center;
}

.share_image_wr img {
    width: 100%;
    max-width: 300px;
}

.uncovered_data_wr {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 29px 0px;
    align-items: center;
    border-radius: 10px;
    padding: 20px;
    margin-top: 18px;
}

.lead_shareIcon .icon_wr {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 29px 0px;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    justify-content: space-around;
}

.lead_data_wr tr,
.lead_data_wr tr th,
.lead_data_wr tr td {
    border: 0;
}

.lead_data_wr tr th {
    vertical-align: baseline;
    line-height: 1.25;
}



.lead_data_wr .lead_shareIcon .modal-body {
    /* position: absolute; */
    width: 80%;
    left: 0;
    background: #fff;
    z-index: 2;
    bottom: 0;
}

.lead_data_wr table {
    border-top: 1px solid #EDEDED;
    border-bottom: 1px solid #EDEDED;
}

.opentradechart,
.tradeQuickLinks {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 29px 0px;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;

}

.tradetitle {
    display: flex;
    justify-content: space-between;
}

.tradeQuickLinks ul {
    list-style: none;
    padding-top: 15px;
    margin-bottom: 40px;
}

.tradeQuickLinks ul li {
    position: relative;
    margin-bottom: 15px;
    color: #231F20;
    font-size: 16px;
    font-weight: 500;
}

.tradeQuickLinks ul li::before {
    content: url('../assets/images/Dashboard/arrow.svg');
    position: absolute;
    left: -26px;
}

.ongoingContests {
    border-radius: 10px;
    background: #00507D;
    box-shadow: 0px 4px 15px 0px rgba(171, 171, 171, 0.25);
    padding: 20px;
    color: #ffffff;
    height: 100%;
    position: relative;
}

.ongoingContests .ongoingContests-h5 {
    font-size: 24px;
    font-weight: 500;
}

.ongoingContests .ongoingContests-p {
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    margin-bottom: 15px;
}

.ongoingContests a {
    color: #ffffff;
    border-radius: 6px;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 400;
}

.ongoingContests a.view_btn {
    margin-left: auto;
    display: inline-block;
    width: 100%;
    text-align: right;
    position: absolute;
    right: 15px;
    bottom: 20px;
}

.ongoingContests p {
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    padding-top: 12px;
}

.ongoingContests .btnsWrap {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.ongoingContests a.blue_btn {
    padding: 5px 25px;
}

.ongoingContests a.blue_btn.light {
    padding: 5px 15px;
}

.todays_incom_box {
    display: flex;
    margin-bottom: 15px;
    flex-direction: column;
    width: 100%;
    border: 1px solid;
    padding: 12px 10px 14px;
    border-radius: 10px;
    border: 1px solid rgba(83, 103, 252, 0.40);
    background: #FFF;
    box-shadow: 0px 4px 15px 0px rgba(171, 171, 171, 0.25);
}

.todays_incom_box span:first-child {
    color: #000;
    font-size: 24px;
    font-weight: 700;
}

.todays_incom_box span {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #545A6D;
}

.total-earning-sub {
    font-size: 12px !important;
    color: rgba(154, 154, 154, 1);
}

.income_wr {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 29px 0px;
    border-radius: 10px;
    margin-top: 12px;
    padding: 20px !important;
}

.income_left_section {
    display: grid;
}

.income_selector {
    grid-column: 16;
}

.recommend_algos {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 29px 0px;
    border-radius: 10px;
    /* margin-top: 12px; */
    padding: 20px !important;
}

.algos_wr {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 29px 0px;
    border-radius: 10px;
    padding: 25px 20px 25px 20px;
    margin-top: 16px;
}

.algos_card {
    border: none;
}

.card-img-top {
    width: 150px !important;
    height: 150px;
    align-self: center;
}

.card-text {
    font-size: 18px;
    font-weight: 600;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.select_wr {
    display: inline-block;
}

.card-body {
    max-height: 10rem;
    overflow: hidden;
}


/* notification */
.noti_header {
    width: 256px;
    background-color: #5367FC;
    border-radius: 6px 6px 0px 0px;
    padding: 10px;

}

.noti_body {
    background-color: #fff;
    border-radius: 0px 0px 6px 6px;
    padding: 12px 10px 0px 16px;
    display: flex;
    align-items: center;
}

.noti_header h6 {
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
}

.msg_text {
    font-size: 10px;
    font-weight: 400;
    margin-bottom: 5px;
}

.noti_time {
    flex-direction: row;
    display: flex;
    align-items: center;
}

.noti_time span {
    margin-left: 16px;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    color: #A3AED0;
}

.msg_wr {
    display: flex;
    flex-direction: column;
    margin-left: 13px;
}

.number_wr span {
    color: #ffffff;
    font-weight: 600;
    font-size: 10px;

}

.noti_icon_wr {
    width: 36px;
    height: 36px;
    padding: 9px 8px 7px 8px;
    border-radius: 24px;
    background-color: #5367FC;
    display: flex;
    justify-content: center;
    align-items: center;
}

.number_wr {
    width: 60px;
    height: 18px;
    background-color: rgba(255, 255, 255, 0.18);
    border-radius: 4px;

    justify-content: center;
    display: flex;
    align-items: center;
}

.noti_text_wr {

    display: flex;
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.see_all_noti_wr {
    width: 70px;
    height: 36px;
    background-color: #fff;
    border-radius: 4px;
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative;
    bottom: -12px;
}

.see_all_noti_wr span {
    font-weight: 500;
    font-size: 12px;
    color: #5367FC;
}

.notification_popup_wr {
    padding: 0px 0px 10px 0px;
}

.noti_footer {
    padding: 10px;
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.noti_footer span {
    padding: 4px 50px 6px 50px;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    background-color: rgba(5, 205, 153, 0.1);
    border-color: rgba(5, 205, 153, 0.1);
    font-weight: 500;
    font-size: 14px;
    color: #05CD99;
}

.profile_popup {
    padding: 10px 0px 10px 0px;
    width: 16em;
}

.profile_item_wr {
    background-color: #5367FC;
    padding: 10px;
    cursor: pointer;
}

.profile_item_wr span {
    font-size: 14px;
    font-weight: 400;
    color: #FFF;
}

.logout_text {
    color: #000 !important;
}

.deactive_color {
    background-color: #FFF;
}

.deactive_img {
    background-color: #FFF !important;
}

.profile_item_wr img {
    background-color: #5367FC;
    margin-right: 5px;
    margin-left: 20px;
}


.path_text {
    padding-left: 10px;
}

.card_wr,
.bg-white,
.lead_summry_container.col-lg-4.col-md-6,
.income_wr,
.refferal_link_section .uncovered_data_wr,
.refferal_link_section .opentradechart {
    background-color: #fff;
}

.refferal_link_section .uncovered_data_wr,
.refferal_link_section .opentradechart {
    background-color: #fff;
    min-height: 300px;
}

.refferal_link_section .opentradechart.card-height {
    min-height: 430px;
}

.mt-center {
    margin-top: 140px !important;
}

.recharts-wrapper>svg {
    margin-left: -20px;
}

.client_wise_income_table_wr table tfoot tr td,
.client_wise_income_table_wr table thead tr th {
    background-color: #f8f8f8;
}

.client_wise_income_table_wr .table-responsive {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 0px 10px 0px;
    border-radius: 10px;
    background-color: #fff;
    margin-top: 15px;
    margin-bottom: 15px;
}

.client_wise_income_table_wr .table-responsive table {
    margin-bottom: 0;
}

.client_wise_income_table_wr .table-responsive table tr td:first-child,
.client_wise_income_table_wr .table-responsive table tr th:first-child {
    padding-left: 20px;
}

.client_wise_income_table_wr .table-responsive table tr td:last-child,
.client_wise_income_table_wr .table-responsive table tr th:last-child {
    padding-right: 20px;
}

.statatics-right-bg {
    position: relative;
    overflow: hidden;
}

.statatics-right-bg:after {
    content: "";
    position: absolute;
    width: 200px;
    height: 200px;
    background-color: #5367fc14;
    right: -90px;
    top: -30px;
    border-radius: 100px;
    z-index: 1;
}

.statatics-right-bg .view_more_text {
    z-index: 2;
}

.Demo__some-network__share-button {
    border-radius: 50%;
}

@media screen and (max-width:1199px) {
    .lead_summry_container .date_picker_wr {
        max-width: 450px;
    }

    .lead_summry_container canvas {
        max-width: 500px;
        max-height: 500px;
        height: auto !important;
        margin-left: 0;
        margin-right: auto;
    }

    .lead_summry_container,
    .income_wr {
        margin-bottom: 20px;
    }

}

@media screen and (max-width:991px) {
    .refferal_link_section .opentradechart.card-height {
        min-height: 210px;
    }

    .mt-center {
        margin-top: 25px !important;
    }

    .col-lg-4.mb-4,
    .lead_summry_container,
    .income_wr {
        margin-bottom: 15px !important;
    }

    .opentradechart,
    .tradeQuickLinks,
    .main-dive {
        margin-top: 15px;
    }

    .row.py-4 {
        padding-top: 15px !important;
    }

    .uncoveredLead_filter {
        padding-bottom: 5px;
    }

    .referral_wr.lead_summry_container {
        flex-direction: column;
    }

    .logo {
        width: auto;
    }

    .profile_wr {
        width: auto;
    }
}

@media screen and (max-width:767px) {

    .uncoveredLead_filter,
    .tradetitle {
        flex-wrap: wrap;
    }

    .uncoveredLead_filter>div:first-child,
    .tradetitle>div:first-child {
        padding-bottom: 15px;
    }

    .lead_filter {
        margin-left: 0;
        margin-right: 0;
    }

    .ongoingContests .ongoingContests-p {
        margin-bottom: 35px;
    }

    .lead_summry_container canvas {
        max-width: 400px;
        max-height: 650px;
        margin-left: auto;
        margin-right: auto;
        min-width: 250px;
    }

    .profile_wr img {
        width: 38px;
        height: auto;
    }

    .lead_summry_container {
        width: auto;
        height: auto;
    }

    .profile_wr>div span:first-child {
        font-size: 12px;
    }

    .profile_wr>div span {
        font-size: 10px;
    }

    .bell_icon {
        margin-left: 10px;
    }

    .notification_wr {
        width: 54px;
    }

    .mobile-toggle-icons {
        display: block;
    }

    .main_nav_wr {
        display: block;
        padding-top: 7px;
        padding-bottom: 7px;
    }

    .menu-open .main_nav_wr {
        display: block;
    }

    .main_nav_wr .main_tabs {
        flex-wrap: wrap;
        padding: 0px 0;
    }

    .main_nav_wr .main_tabs ul {
        margin: 0 0px 0px 0 !important;
        padding: 0;
        width: auto;
    }

    .main_nav_wr .main_tabs ul a {
        padding: 10px 2px;
    }

    .top_sub_menu_wr {
        display: none;
    }

    .main_nav_wr .main_tabs ul img {
        display: none;
    }

    .main_nav_wr .contact {
        padding: 0 0 0 20px;
    }

    .main_nav_wr .sidebar-close {
        display: none;
    }

    .refferal_link_section .col-lg-9 .referral_text_wr {
        padding-left: 0;
        padding-right: 0;
    }

    .css-fmb7cv {
        width: 100% !important;
        max-width: 320px;
    }

    /* .recharts-wrapper,
    .recharts-wrapper svg {
        width: 100% !important;
        min-width: 100% !important;
    } */

    .client_wise_income_table_wr tr td {
        font-size: 14px;
    }

    .button-container {
        display: flex;
        justify-content: space-around;
        /* Adjust as needed */
    }

    button {
        /* Your default button styles */
        margin: 5px;
        padding: 10px;
        cursor: pointer;
    }

    .selected {
        /* Your selected button styles */
        background-color: #3498db;
        color: #fff;
    }

    .refferal_link_section .uncovered_data_wr .btn {
        width: auto;
        padding: 2px 10px;
    }

    .Demo__some-network__share-button {
        width: 40px;
    }

    .income-box {
        font-size: 12px;
    }

    .income-icon {
        width: 10px !important;
        height: 10px !important;
    }
}


.table-pagination {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
}

.table-pagination button {
    background: #fff;
    border: 1px solid #5367fc;
    border-radius: 4px;
    margin-left: 2px;
    margin: 0 5px;
    min-width: 28px;
}

.table-pagination button.active-page {
    background: #5367FC;
    color: #fff;
}

.table-pagination button.active {
    color: #5367FC;
}

.table-pagination button.inactive {
    color: #5367FC;
    opacity: 0.5;
}

.table-pagination .pagination-buttons span {
    padding: 2px 10px;
    border: 1px solid #ccc;
}

.canvasjs-chart-credit {
    display: none;
}

.apexcharts-canvas {
    margin: auto;
}

.shere-box {
    position: relative;
    width: 100%;
}

.shere-popup {
    width: 105px;
    height: auto;
    padding: 9px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.45);
    position: absolute;
    right: 0px;
    top: -12px;
    box-sizing: border-box;
    background: #fff;
    z-index: 9;
}

.close-btn {
    display: table;
    margin: 0;
    font-size: 20px;
    position: absolute;
    right: 8px;
    top: 0;
    cursor: pointer;
}

.popup-title {
    font-size: 16px;
}

@media screen and (max-width:767px) {
    .shere-popup {
        width: 116px;
        right: 59px;
    }

    .referral_text {
        font-size: 14px;
    }
}

@media screen and (max-width:1199px) and (min-width:992px) {
    .date_picker_wr {
        flex: 0 0 60%;
    }

    .income_client button {
        padding: 10px;
        font-size: 12px !important;
    }
}

.loader-container img {
    margin: auto;
    display: table;
}

.monthly-chart {
    min-height: 360px !important;
}

@media screen and (max-width:767px) {
    .monthly-chart {
        padding: 20px 0;
    }

    .monthly-chart .chart-title {
        padding: 0 20px;
    }
}

.profile-drop {
    position: relative;
    display: none;
}

.profile-drop .profile-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
}

.profile-drop .profile-icon:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-top: 5px solid #000;
    position: absolute;
    right: 0;
    top: 42%;
    transform: translateY(-50%);
}

.profile-drop .profile-icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.profile-drop .dropdown-item {
    width: 150px;
    position: absolute;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
    background: #fff;
    right: 0;
    z-index: 99;
}

.profile-drop .dropdown-item ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.profile-drop .dropdown-item ul li {
    margin-bottom: 10px;
}

.profile-drop .dropdown-item ul li.name {
    font-size: 16px;
    font-weight: 600;
    white-space: break-spaces;
}


@media screen and (max-width:767px) {
    .profile-drop {
        display: block;
    }

    .lead_filter {
        flex: 0 0 150px;
    }

    .f-14-label {
        font-size: 14px;
        line-height: 20px;
    }
}

.android-os .whatsapp {
    display: none;
}

@media only screen and (max-width: 767px) {
    .mobile-d-none {
        display: none;
    }
}

.contact-modal-wraper {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;
    top: 0;
    left: 0;
    padding: 10px;
}

.contact-modal {
    width: 100%;
    max-width: 400px;
    height: auto;
    position: relative;
    border-radius: 12px;
    padding: 20px 25px;
    background: #fff;
}

.contact-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.contact-header span {
    cursor: pointer;
}

.text-body {
    width: 100%;
    height: auto;
    position: relative;
    background: #f8f9fe;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    box-sizing: border-box;
}

.contact-list {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    justify-content: space-between;
}

.contact-list p {
    flex: 0 0 40%;
}

.contact-list h6 {
    text-align: right;
    width: 100%;
}

.modal-close-btn {
    margin: auto;
    border: none;
    padding: 10px 30px;
    border-radius: 5px;
    background: #5367fc;
    color: #fff;
    font-weight: 600;
    display: table;
}

@media only screen and (max-width: 767px) {
    .contact-list {
        display: block;
    }

    .contact-list p {
        margin-bottom: 0;
    }

    .contact-list {
        margin-bottom: 20px;
    }

    .contact-list h6 {
        text-align: left;
        font-size: 20px;
    }
}