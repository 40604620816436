.Left-container {
  align-items: center;
  justify-content: center;
}

.Right-side {
  padding-top: 30px;
}

.mide {
  justify-content: center;
  align-items: center;
}

.Icon-container {
  /* margin-top: 30px; */
  margin-left: 100px;

}

.Login-text {
  font-size: 20px;
  font-weight: 500;
  color: #545A6D;
  display: flex;
  justify-content: center;
  line-height: 30px;
}

.text-container {
  justify-content: center;
  align-items: center;

}

.align-self-center {
  display: flex;
  justify-content: right;
}

.mide-section {
  align-self: start;
}

.Card-view {
  display: flex;
  height: 50%;
  margin-top: 10%;
}

.mobile-icon {
  width: 24px;
  height: 24px;
}

.Icon-container {
  display: flex;
  flex-direction: row;
}

.Ipute-mobile {
  border-radius: 30px;
  font-size: 16px;
  border-color: #F0F0F0;
  width: 522px;
  line-height: 24px;
  padding: 12px;
  color: #D3D3D3;
  border-width: .1px;
}

.mobile-number-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  flex-direction: column
}

.edit-number {
  color: #5367FC;
  text-decoration: underline;
  margin-left: 10px;
}

.Mobile-numebr-text {
  color: #545A6D;
  font-weight: 500;
  margin-left: 10px;
}

.Inpute-bottom-text {
  margin-top: 10px;
  margin-left: 25px;
  color: #797979;
  font-size: 14px;
}

.otp-text-container {
  margin-top: 20px;
}

.botton-style {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  justify-items: center;

}

.botton-conatiner {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.otp-text {
  margin-left: 100px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  font-family: Poppins;
}