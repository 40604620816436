.main-dive {
  margin-top: 20px;
}

.income_cal_wr {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 29px 0px;
  /* display: flex; */
  align-items: center;
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;

}

.total_income_wr {
  background-color: #F3F3FF;
  padding: 15px;
  border-radius: 6px;
}

.total_income_wr div:first-child {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.total_inc_text {
  font-size: 24px;
  font-weight: 500;
  color: #545A6D;
}

.total_income_rupee {
  font-size: 30px;
  font-weight: 600;
  color: #231F20;
  margin-left: 15px;
}

.total_inc_blue {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #5367FC;
}

.total_client_sky_blue {
  background-color: #53DEFC;
}

.income_button_first {
  padding: 5px 16px;
  background-color: #5367FC;
  border: none;
  border-radius: 50px;

}

.ative_button {
  background-color: #5367FC;
}

.deative_button {
  background-color: #F0F0F0;
}

.ative_button_text span {
  color: #F0F0F0;
}

.deactive_btn_text span {
  color: #231F20;
}

.client_wr {
  color: #231F20;
  font-size: 12px;
  font-weight: 500;
}

.income_wr_first {
  color: #FFFFFF;
}

.income_client_switch_wr {
  overflow: hidden;
  border-radius: 50px;
  background-color: #F0F0F0;
  padding: 1px !important;
  height: fit-content;
}

.form-select {
  border: none;
  padding: 9px 60px 9px 30px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 29px 0px;
}

.trading_back_color {
  background-color: #0A3294;
}

.mutual_fund_back {
  background-color: #00FFC1;
}

.third_party_abc {
  background-color: #53DEFC;
}

.sturct_prod_back {
  background-color: #F15B4D;
}

.portfolio_manag_back {
  background-color: #53DEFC;
}

.struct_title {
  font-size: 14px;
  font-weight: 500;
  white-space: normal !important
}

.monthaly_catwise_income {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 29px 0px;
  border-radius: 0px, 0px, 6px, 6px;
}

.monthaly_catwise_income tr td {
  border-bottom: 1px;
  border-style: solid;
  border-color: #C8C8C840;
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.monthly_cat_select {
  justify-content: space-between;
}

.monthly_cat_select h4,
.client_wise_income_table_wr h4 {
  font-size: 20px;
  font-weight: 600;
  color: #545A6D;
}

.income_cat_table .lead_shareIcon .modal-body {
  position: absolute;
  width: 100%;
  left: 0;
  background: #fff;
  bottom: 0;
}

.income_cat_table tr,
.income_cat_table tr td {
  border: 0;
}

.borkerage_table tr,
.borkerage_table tr td {
  border: 0;
}

.income_cat_table tr th {
  border-bottom: 1px;
  border-style: solid;
  border-color: #C8C8C840;
  color: #808080;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.brokarage_snap_wr {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 29px 0px;
  /* display: flex; */
  align-items: center;
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
}

.borkerage_table span {
  font-size: 18px;
  font-weight: 500;
  color: #545A6D;
  padding-bottom: 10px;
}

.brokerage_tab_body {
  padding: 10px;
}

.brokerage_tab_body tr th {
  border-bottom: 1px;
  border-style: solid;
  border-color: #C8C8C840;
  color: #808080;
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
}

.brokerage_tab_body tr td {
  border-bottom: 1px;
  border-style: solid;
  border-color: #C8C8C840;
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
}

.client_wise_income_table_wr {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 0px 10px 0px;
  align-items: center;
  border-radius: 10px 10px 0 0;
  padding: 20px;
  background-color: #fff;
}

.clien_wise_text {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 0px 8px 0px;
  ;
  /* display: flex; */
  align-items: center;
  border-radius: 10px;
  padding: 10px;
}

.clien_wise_text h4 {
  font-weight: 600;
  color: #545A6D;
  font-size: 20px;
  padding-left: 10px;
}

.table_footer {
  border-radius: 0 0 10px 10px;
  padding: 10px;
  background-color: #f6f6f6;
  justify-content: space-between;
}

.table_footer ul {
  margin: 0;
  padding: 0;
}

.table_footer h4 {
  font-weight: 600;
  color: #545A6D;
  font-size: 20px;
}

.mxwidth-200 {
  max-width: 200px;
}

.client_wise_income_table_wr tr,
.client_wise_income_table_wr tr td {
  border: 0;
}

/* .client_wise_income_table_wr tr th:hover{
  color: #5367FC;
} */

.monthly_cat_selet {
  justify-content: space-between;
}

.client_wise_income_table_wr tr th {
  border-bottom: 1px;
  border-style: solid;
  border-color: #C8C8C840;
  color: #808080;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding: 12px 8px 12px 8px;
}

.client_wise_income_table_wr tr th>img {
  margin-left: 10px;
}

.client_wise_income_table_wr tr th>div {
  display: inline-block;

}

.client_wise_income_table_wr tr td {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding: 12px 8px 12px 8px;
  justify-content: center;
}

.client_wise_income_table_wr tr td:first-child,
.client_wise_income_table_wr tr td:nth-child(3) {
  min-width: 135px;
}

.overall_profit_col {
  align-items: center;
}

.overall_profit_icon {
  margin-left: 25px;

}

.table_footer span {
  font-size: 16px;
  font-weight: 400;
  color: #808080;
  margin-left: 25px 0 0 25px;

}

.deactive_circle {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #BABABAA6;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-left: 10px;
}

.active_circle {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #BABABAA6;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-left: 10px;
}

.active_circle {
  border-width: 2px;
  border-style: solid;
  border-color: #5367FC;
  background-color: #f6f6f6;
}

.active_number {
  background-color: #5367FC;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.deactive_number {
  background-color: #f6f6f6;

  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 1px;
}

.active_num {
  color: #fff;
  font-weight: bolder;
  /* color:#5367FC; */
  font-size: 16px;
  font-weight: 600;
}

.deactive_num {
  color: #5367FC;
  font-weight: bolder;
  font-size: 16px;
}

.dots {
  color: #000000 !important;
  font-weight: bolder !important;
}


.lead_details_no span {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  line-height: 24px;
}

.lead_details_no {
  background-color: #78FFDEB2;
  padding: 2px;
  justify-content: center;
  align-items: center;
  padding: 14px;
}

.show_ertry_wr {
  align-items: center;
  justify-content: end;
}

.lead_filter {
  margin-left: 5px;
  margin-right: 5px;
}

.show_text {
  font-size: 16px;
  font-weight: 500;
}

.search_name_no input {
  max-width: 350px;
  border: 1px;
  border-style: solid;
  background-color: #fff;
  border-color: #D6D3D3;
  border-radius: 10px;
}

.calendar-icon {
  justify-content: flex-start;
  margin-right: 30px;
}


.calendar_icon_body {
  border: 1px;
  border-style: solid;
  border-color: #D6D3D3;
  border-radius: 10px;
  width: 180px;
  padding: 0px 8px;

}

.date-picker-icon {
  align-items: center;
}

.calendar_icon img {
  position: inherit;
}

.calander_placeholder_text {
  margin-left: 10px;
}

.date_wr {
  display: inline-block;
}

.calendar_wr {
  justify-content: space-between;
  align-items: center;
}

.calendar_wr span {
  font-size: 16px;
  font-weight: 500;
  color: #787878
}

.checkbox_lable {
  color: #231F20;
  font-size: 16px;
  font-weight: 500;
}

.search_result_wr {
  position: absolute;
  z-index: 2;
  padding: 6px 0;
}

.filter_box_wr {
  position: absolute;
  z-index: 2;
  background-color: #FFFFFF;
  border-radius: 6px;
  padding: 10px;
  width: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 29px 0px;

}

.result_box {
  list-style: none;
  padding: 0px;
  background-color: #FFFFFF;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 29px 0px;
}


.result_box li:hover {
  background-color: #5367FC;
  color: #fff;
}

.result_box li:hover .content_text {
  background-color: #5367FC;
  color: #fff;
}

.result_box li:hover .content_text_value {
  background-color: #5367FC;
  color: #fff;
}

.result_box li {
  padding: 10px 35px;
  border-bottom: 1px solid;
  border-color: #BABABAA6;
}

/* filter box */
.filter_box {
  list-style: none;
  padding: 0;
  margin-bottom: 0rem;
}


.filter_box li:hover {
  background-color: #5367FC;
  color: #fff;
}

.filter_box li:hover .filter_text {
  background-color: #5367FC;
  color: #fff;
}

.filter_box li:hover .filter_text_value {
  background-color: #5367FC;
  color: #fff;
}

.filter_box li {
  padding: 6px;
  border-bottom: 1px solid;
  border-color: #BABABAA6;
}

.content_text {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #808080;
}

.content_text_value {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #231F20;
  margin-right: 10px;
  margin-left: 5px;
}

.filter_text {
  font-size: 12px !important;
  font-weight: 500 !important;
}

.filter_text_value {
  font-size: 11px !important;
  font-weight: 500 !important;
  line-height: 24px;
  margin-right: 5px;
  margin-left: 2px;
}


.popup_body .filter_cechbox .form-check-label {
  color: #231F20;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.income_cat_table.monthaly_catwise_income.mt-4 {
  margin-left: auto;
  margin-right: auto;
  min-width: 70%;
  max-width: calc(100% - 30px);
}

/* YourComponent.css */

.income_client_switch_wr {
  overflow: hidden;
  border-radius: 50px;
  background-color: #f0f0f0;
  padding: 1px !important;
  /* height: fit-content; */
}

.income_client {
  display: flex;
  justify-content: space-around;
  background-color: #f0f0f0;
  /* padding: 1px !important; */
  overflow: hidden;
  border-radius: 50px;
  /* height: fit-content; */
  /* align-items: center;
  align-self: center; */
  /* Adjust as needed */
}

button {
  border: none;
  padding: 6px 30px 6px 32px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 29px 0px;
  cursor: pointer;
}

.selected {
  background-color: #0a3294;
  color: #fff;
}
.client_wise_income_table_wr .pagination .btn.active{
  border-radius: 0px;
  background: #0d6efd;
  border-color:#0d6efd;
  color: #fff;
  opacity: 1;
}

@media screen and (max-width: 768px) {
  button {
    padding: 6px 10px 6px 10px;
    width: 33%;
  }

  .search_bar {
    margin: 10px 0;
  }

  .total_income_wr div:first-child {
    justify-content: space-between;
  }

  .total_income_rupee {
    font-size: 18px;
    margin-left: 0;
  }

  .total_inc_text {
    font-size: 14px;
  }

  .income_client_switch_wr button {
    width: 100px;
  }

  .client_wise_income_table_wr .clien_wise_text1 {
    display: block !important;
  }

  .client_wise_income_table_wr .pagination {
    flex-wrap: wrap;
  }

  .client_wise_income_table_wr .pagination .page-link {
    width: 45px;
    margin-bottom: 5px;
  }
  .client_wise_income_table_wr .pagination .btn{
    width: auto;
  }
 
  .client_wise_income_table_wr .table-responsive table tr td:last-child {
    padding: 12px 8px;
    white-space: nowrap;
  }
}